import React, { useEffect, useState } from 'react';
import StateSelectDropdown from 'components/StateSelectDropdown';
import { TextField } from 'lib/components/TextField';
import { isFieldNonEmpty } from '../individual/RegisterIndividualForm';
import * as validators from './validators';

export type RegisterOrganizationAddress = {
  name: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: {
    id: number;
    label: string;
  };
  zipCode: string;
  phone: string;

  validation: {
    valid: boolean;
  };
};

export const EMPTY_ADDRESS: RegisterOrganizationAddress = {
  name: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: {
    id: 0,
    label: ''
  },
  zipCode: '',
  phone: '',
  validation: {
    valid: false
  }
};

type RegisterOrganizationAddressFormProps = {
  handleInputChanged: (input: RegisterOrganizationAddress) => void;
};

/**
 * Common form elements between all organization registration flows.
 */
export default function RegisterOrganizationAddressForm({
  handleInputChanged
}: RegisterOrganizationAddressFormProps) {
  const [name, setName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState({
    id: 0,
    label: ''
  });
  const [zipCode, setZipCode] = useState('');
  const [phone, setPhone] = useState('');

  const isZipValid = !zipCode || validators.zipAdvertiser(zipCode);
  const isPhoneValid = !phone || validators.phoneRegex.test(phone);

  const isNameValid = isFieldNonEmpty(name);
  const isNameValidDisplayError = !name || isNameValid;
  const isAddressLine1Valid =
    isFieldNonEmpty(addressLine1) && validators.addressField(addressLine1);
  const isAddressLine1ValidDisplayError = isAddressLine1Valid || !addressLine1;
  const isAddressLine2Valid =
    !addressLine2 || validators.addressField(addressLine2);
  const isCityValid = isFieldNonEmpty(city) && validators.addressField(city);
  const isCityValidDisplayError = isCityValid || !city;

  const runValidators = () => {
    if (
      !isNameValid ||
      !isAddressLine1Valid ||
      !isAddressLine2Valid ||
      !isCityValid ||
      !isZipValid ||
      !isPhoneValid ||
      !name
    ) {
      return {
        valid: false
      };
    }

    const allRequiredFilled = [
      addressLine1,
      city,
      state.label,
      zipCode,
      phone,
      name
    ].every(x => x && x.length > 0);
    if (!allRequiredFilled) {
      return { valid: false };
    }

    return {
      valid: true
    };
  };

  const address: RegisterOrganizationAddress = {
    name,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    phone,
    validation: runValidators()
  };

  // When any address field changes, file a composite onChange event
  useEffect(() => {
    handleInputChanged(address);
  }, [name, addressLine1, addressLine2, city, state, zipCode, phone]);

  return (
    <div className="space-y-4">
      <TextField
        id="name"
        required
        value={name}
        onChange={setName}
        placeholder="Name of Organization"
        labelText="Name of organization"
        errorText={isNameValidDisplayError ? '' : 'Name must be valid.'}
      />
      <TextField
        id={'phone'}
        required
        type={'tel'}
        value={phone}
        placeholder={'Phone'}
        onChange={setPhone}
        labelText="Phone number"
      />
      <TextField
        id={'address-line-1'}
        required
        value={addressLine1}
        onChange={setAddressLine1}
        placeholder={'Address line 1'}
        labelText={'Address line 1'}
        errorText={
          isAddressLine1ValidDisplayError ? '' : 'Address must be valid.'
        }
      />
      <TextField
        id={'address-line-2'}
        value={addressLine2}
        onChange={setAddressLine2}
        placeholder={'Address line 2'}
        labelText={'Address line 2'}
        errorText={isAddressLine2Valid ? '' : 'Address must be valid.'}
      />
      <TextField
        id={'city'}
        required
        value={city}
        onChange={setCity}
        placeholder={'City'}
        labelText={'City'}
        errorText={isCityValidDisplayError ? '' : 'City must be valid.'}
      />
      <div>
        <p className="text-column-gray-800 font-medium text-sm leading-5 mb-2">
          State *
        </p>
      </div>
      <StateSelectDropdown onChange={value => setState(value)} value={state} />
      <TextField
        id={'zipCode'}
        required
        type={'postal-code'}
        value={zipCode}
        placeholder={'Zip Code'}
        onChange={setZipCode}
        labelText="Zip Code"
      />
    </div>
  );
}
